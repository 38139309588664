<template>
  <div>
    <component
      :is="containerComponent"
      :placeholder-message="$t('available.message', { itemName: $t('rooms.title') })"
      :items="rooms"
      :has-more="itemss.length < total"
      :total="total"
      :loading="loading"
      :loading-next-page="isLoadingNextPage"
      :per-page="perPage"
      :placeholder="placeholder"
      @load-next="handleLoadNextPage"
    >
      <template #default="{ item }">
        <rooms-section :room="item" />
      </template>
    </component>
  </div>
</template>

<script>
import ItemsListMixin from '@core/components/containers/mixins/ItemsListMixin';
import Placeholder from '@/assets/images/placeholders/light/rooms.svg';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import {
  ROOMS_STORE_MODULE_NAME, ROOMS_GETTERS,
} from '@/views/apps/rooms/constants/rooms-store-constants';
import roomsStoreModule from '@/views/apps/rooms/store/roomsStoreModule';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import FileTypes from '@/@core/constants/FileTypes';
import vSelect from 'vue-select';
import { quillEditor } from 'vue-quill-editor';
import Service from '@/config/service-identifiers';
import RoomsSection from './RoomsSection.vue';

export default {
  name: 'RoomsList',
  components: {
    RoomsSection,
    vSelect,
    quillEditor,
  },
  mixins: [ItemsListMixin],
  props: {
    perPage: {
      type: Number,
      default: 8,
    },
    small: Boolean,
  },
  data() {
    return {
      formInput: {
        name: null,
        description: '',
        modality: null,
        app: null,
        type: null,
      },
      itemImage: null,
      imageSrc: null,
      selectedOption: this.$t('rooms.online'),
      selectedApp: 'Conferencing',
      selectedType: 'Meeting',
      questionsToShow: [],
      appRoom: [
        "Bluejeans",
        "Nectios Classroom",
        "Zoom.us",
        "Jisti",
      ],
      room: [],
      isEditModalOpened: false,
    };
  },
  computed: {
    FileTypes() {
      return FileTypes;
    },
    typeEventsOptions() {
      return [this.$t('events.event-details.online-event'), this.$t('events.event-details.on-site-event'), this.$t('events.event-details.hybrid')];
    },
    appsOptions() {
      return this.appRoom;
    },
    typeOptions() {
      return ['Meeting', 'Webinar'];
    },
    placeholder() {
      return Placeholder;
    },
    total() {
      return this.$store.getters[ROOMS_GETTERS.total];
    },
    isAuditorium() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].mainRoom;
    },
    rooms() {
      return this.itemss.filter(({ isMain }) => isMain === false);
    },
    itemsData() {
      return this.$store.getters.rooms;
    },
    itemss() {
      if (!this.itemsData || this.itemsData.length === 0 || !this.itemsData.unpaginated) {
        return [];
      }
      return this.itemsData.unpaginated;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule(ROOMS_STORE_MODULE_NAME)) {
      this.$store.registerModule(ROOMS_STORE_MODULE_NAME, roomsStoreModule);
    }
  },
  async created() {
    await this.fetchData();
    this.selectQuestions();
  },
  methods: {
    translate(string) {
      return translateTranslationTable(this.currentLocale, string);
    },
    selectQuestions() {
      this.questionsToShow = [];
      this.isLoading = true;
      for (let row of this.appRoom) {
        this.questionsToShow.push({ name: this.translate(row.name), key: row.key });
      }
    },
    async getApp() {
      const response = await this.$store.$service[Service.BackendClient].get("addons/rooms/installed", { 
        params: { communitySlug: this.communitySlug }
      });
      this.appRoom[0] = 'Conferencing';
      if (response.data) {
        for (let row of response.data) {
          this.appRoom.push(row.name.en);
        }
      }
    },
    modality() {
      switch (this.selectedOption) {
        case this.typeEventsOptions[1]:
          this.formInput.modality = 'physical';
          return;
        case this.typeEventsOptions[2]:
          this.formInput.modality = 'hybrid';
          return;
        case this.typeEventsOptions[0]:
          this.formInput.modality = 'online';
          return;
      }
    },
    appss() {
      switch (this.selectedApp) {
        case 'Conferencing':
          this.formInput.app = 'jitsi';
          return;
        case 'Zoom.us':
          this.formInput.app = 'zoom';
          return;
        case 'Nectios Classroom':
          this.formInput.app = 'classroom';
          return;
        case 'Bluejeans':
          this.formInput.app = 'bluejeans';
          return;
        case 'Jisti':
          this.formInput.app = 'jitsi';
          return;
      }
    },
    type() {
      switch (this.selectedType) {
        case this.typeOptions[0]:
          this.formInput.type = 'meeting';
          return;
        case this.typeOptions[1]:
          this.formInput.type = 'webinar';
          return;
      }
    },
    resetInputs() {
      this.formInput = {};
      this.itemImage = null;
      this.imageSrc = null;
    },
    async handleCreateItem() {
      // Name is required
      if (!this.formInput.name) {
        this.formError = false;
        return;
      }
      try {
        this.modality();
        this.appss();
        this.type();
        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'rooms',
            requestConfig: {
              name: this.formInput.name,
              description: this.formInput.description,
              modality: this.formInput.modality,
              app: this.formInput.app != '' ? this.formInput.app : null ,
              type: this.formInput.type,
            },
          },
          file: this.itemImage,
        });
        this.resetInputs();
        this.productInput = {};
        this.formError = null;
        this.itemImage = null;
        this.selectedOption = this.$t('rooms.online');
        this.selectedApp = 'Conferencing';
        this.selectedType = 'Meeting';
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    async loadPage({}) {


      return '';
    },
    async fetchData() {
      if (this.itemsData.unpaginated.length === 0) {
        await this.$store.dispatch('getItems', {
          itemType: 'rooms',
          page: 1,
          forceAPICall: true,
          requestConfig: {
            orderByDate: -1,
          },
        });
      }
    },
  },
};
</script>
