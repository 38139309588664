<template>
  <list-container-widget
    id="rooms-list"
    :app-id="app.id"
    :view-more-location="getLocation('rooms')"
    :size="getSize('rooms')"
    stretch
  >
    <template #actions>
      <ViewAllButton item-type="rooms" />
    </template>

    <!-- Room list -->
    <rooms-list />
    
  </list-container-widget>
</template>

<script>
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import RoomsList from '@/views/apps/rooms/components/RoomsList.vue';
// import { getImageResource } from '@/@core/utils/image-utils';

export default {
  name: 'RoomsListWidget',
  components: {
    ListContainerWidget,
    RoomsList,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      formInput: {
        name: null, description: '', modality: null, app: null, type: null,
      },
      itemImage: null,
      imageSrc: null,
      locations: [],
      isSaving: false,
      selectedOption: this.$t('rooms.online'),
      selectedApp: 'Conferencing',
      selectedType: 'Meeting',
    };
  },
  computed: {
    isStaff() {
      return this.collective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    typeEventsOptions() {
      return [this.$t('rooms.online'), this.$t('rooms.on-site'), this.$t('rooms.hybrid')];
    },
    typeOptions() {
      return ['Meeting', 'Webinar'];
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
  },
  async created() {
    //await this.getConferencingApps();
  },
  methods: {
    // getImageResource(url) {
    //   return getImageResource(url);
    // },
    // async getConferencingApps() {
    //   // await this.$store.dispatch('getItems', {
    //   //     itemType: 'addons/rooms/installed',
    //   //     customName :'appRoom'
    //   //   });
    //   const response = await this.$store.$service[Service.BackendClient].get('addons/rooms/installed', {
    //     params: { communitySlug: this.communitySlug },
    //   });
    //   this.appRoom[0] = 'Conferencing';
    //   if (response.data) {
    //     for (const row of response.data) {
    //       this.appRoom.push(row.name.en);
    //     }
    //   }
    // },
    modality() {
      switch (this.selectedOption) {
        case this.typeEventsOptions[1]:
          this.formInput.modality = 'physical';
          return;
        case this.typeEventsOptions[2]:
          this.formInput.modality = 'hybrid';
          return;
        case this.typeEventsOptions[0]:
          this.formInput.modality = 'online';
      }
    },
    appss() {
      switch (this.selectedApp) {
        case 'Conferencing':
          this.formInput.app = 'jitsi';
          return;
        case 'Zoom.us':
          this.formInput.app = 'zoom';
          return;
        case 'Nectios Classroom':
          this.formInput.app = 'classroom';
          return;
        case 'Bluejeans':
          this.formInput.app = 'bluejeans';
          return;
        case 'Jisti':
          this.formInput.app = 'jitsi';
      }
    },
    type() {
      switch (this.selectedType) {
        case this.typeOptions[0]:
          this.formInput.type = 'meeting';
          return;
        case this.typeOptions[1]:
          this.formInput.type = 'webinar';
      }
    },
    resetInputs() {
      this.formInput = {};
      this.itemImage = null;
      this.imageSrc = null;
    },
    async handleCreateItem() {
      // Name is required
      if (!this.formInput.name) {
        this.formError = false;
        return;
      }
      try {
        this.isSaving = true;
        this.modality();
        this.appss();
        this.type();
        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'rooms',
            requestConfig: {
              name: this.formInput.name,
              locations: this.locations != null && this.locations.length > 0 ? this.locations : null,
              description: this.formInput.description,
              modality: this.formInput.modality,
              app: this.formInput.app != '' ? this.formInput.app : null,
              type: this.formInput.type,
              startURL: this.formInput.startURL,
            },
          },
          file: this.itemImage,
        });
        this.resetInputs();
        this.productInput = {};
        this.formError = null;
        this.itemImage = null;
        this.selectedOption = this.$t('rooms.online'),
        this.selectedApp = 'Conferencing';
        this.selectedType = 'Meeting';
        this.closeModal();
        this.notifySuccess(this.$t('success-message.general-success-create'));
        this.isSaving = false;
      } catch {
        this.closeModal();
        this.notifyError(this.$t('error-message.general-error'));
        this.isSaving = false;
      }
    },
    closeModal() {
      this.$bvModal.hide('modal-create-rooms');
    },
  },
};
</script>
